import Model, { attr } from '@ember-data/model';

import type { Amount } from 'qonto/react/models/amount';

export default class RibaPaymentModel extends Model {
  @attr declare amount: Amount;
  @attr('string') declare status: string;
  @attr('date') declare approvedAt: Date;
  @attr('string') declare bankAccountId: string;
  @attr('date') declare canceledAt: Date;
  @attr('string') declare creditorName: string;
  @attr('date') declare debitedAt: Date;
  @attr('date') declare executionDate: Date;
  @attr('string') declare failureReason: string;
  @attr('string') declare receiptUrl: string;
  @attr('date') declare receivedAt: Date;
  @attr('string') declare reference: string;
  @attr('date') declare rejectedAt: Date;
  @attr('date') declare declinedAt: Date;
  @attr('string') declare ribaNumber: string;
  @attr('boolean') declare canUpdate: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'riba-payment': RibaPaymentModel;
  }
}
