import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';
import { alias, equal, or } from 'macro-decorators';

// @ts-expect-error
import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import CURRENCIES from 'qonto/constants/currencies';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import Request from 'qonto/models/request';
// @ts-expect-error
import { differenceInCalendar, getCurrentParisDateString } from 'qonto/utils/date';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
// @ts-expect-error
import TransferValidations from 'qonto/validations/transfer';

// @ts-expect-error
export default class RequestTransferModel extends Request.extend(TransferValidations) {
  @service declare organizationManager: Services['organizationManager'];

  @attr('boolean') declare notifyByEmail: boolean;
  @attr('string', { defaultValue: 'transfer' }) declare requestType: string;
  @attr('string') declare amount: string;
  @attr('string') declare activityTag: string;
  @attr('string') declare note: string;
  @attr('string') declare sequentialId: string;
  @attr('string') declare slug: string;
  @attr('string', { defaultValue: '' }) declare reference: string;
  @attr('string', { defaultValue: 'pending' }) declare status: string;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string', { defaultValue: 'scheduled' }) declare operationType: string;
  @attr('string', { defaultValue: 'iban' }) declare accountType: string;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  // @ts-expect-error
  createdAt;
  // @ts-expect-error
  @attr() beneficiaryIban;
  // @ts-expect-error
  @attr() beneficiaryId;
  // @ts-expect-error
  @attr() beneficiaryBic;
  // @ts-expect-error
  @attr() beneficiaryName;
  // @ts-expect-error
  @attr() beneficiaryEmail;
  @attr('string', { defaultValue: getCurrentParisDateString }) declare scheduledDate: string;
  @attr('number') declare standingOn: number;
  @attr('string') declare standingEndingDate: string; // YYYY-MM-DD

  // @ts-expect-error
  @belongsTo('beneficiary', { async: true, inverse: null }) beneficiary;
  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;

  // @ts-expect-error
  @hasMany('attachment', { async: true, inverse: null }) attachments;
  // @ts-expect-error
  @hasMany('label', { async: false, inverse: null }) labels;

  originalBeneficiaryEmail = null;

  // @ts-expect-error
  @equal('status', 'approved') isApproved;
  // @ts-expect-error
  @equal('accountType', ACCOUNT_TYPES.IBAN) isIBANAccount;
  // @ts-expect-error
  @equal('operationType', 'scheduled') once;
  // @ts-expect-error
  @equal('operationType', 'scheduled_later') later;
  // @ts-expect-error
  @equal('operationType', SCHEDULE_OPERATION_TYPES.WEEKLY) weekly;
  // @ts-expect-error
  @equal('operationType', SCHEDULE_OPERATION_TYPES.MONTHLY) monthly;
  // @ts-expect-error
  @equal('operationType', SCHEDULE_OPERATION_TYPES.QUARTERLY) quarterly;
  // @ts-expect-error
  @equal('operationType', SCHEDULE_OPERATION_TYPES.HALF_YEARLY) halfYearly;
  // @ts-expect-error
  @equal('operationType', SCHEDULE_OPERATION_TYPES.YEARLY) yearly;
  // accessing `ManyArray` using `content` property
  // @ts-expect-error
  @alias('attachments.content.0') attachment;
  // @ts-expect-error
  @alias('savedAttachments.length') attachmentCount;
  // @ts-expect-error
  @or('weekly', 'monthly', 'quarterly', 'halfYearly', 'yearly') standing;

  get savedAttachments() {
    // @ts-expect-error
    return this.attachments.filter(item => get(item, 'isNew') === false);
  }

  get shouldHaveAttachments() {
    let { max_amount_without_attachment_cents } =
      this.organizationManager.organization.transferSettings;
    let maxAmount = max_amount_without_attachment_cents / 100;

    // @ts-expect-error
    return this.amount >= maxAmount;
  }

  get isIBANObfuscated() {
    let iban = this.beneficiary.get('iban');

    return iban ? /^(?:••••|xxxx)/i.test(iban) : false;
  }

  get attachmentsFiles() {
    // @ts-expect-error
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  get occurrences() {
    if (!this.standing) {
      return null;
    }

    // @ts-expect-error
    let countOccurrences = unit => {
      // dayjs rounds to 0, not to nearest integer
      return dayjs(this.standingEndingDate).diff(this.scheduledDate, unit) + 1;
    };

    if (this.weekly) {
      return countOccurrences('weeks');
    } else if (this.monthly) {
      return countOccurrences('months');
    } else if (this.quarterly) {
      return countOccurrences('quarters');
    } else if (this.halfYearly) {
      return Math.round(countOccurrences('quarters') / 2);
    }

    return countOccurrences('years');
  }

  get hasPastEndingDate() {
    if (this.standing) {
      let nbDays = differenceInCalendar(new Date(), this.standingEndingDate, 'day');
      return nbDays >= 0;
    }
    return false;
  }

  get hasPastDate() {
    if (this.later || this.standing) {
      let nbDays = differenceInCalendar(new Date(), this.scheduledDate, 'day');
      return nbDays >= 0;
    }

    return false;
  }

  get dasherizedRequestType() {
    return 'transfer';
  }

  // @ts-expect-error
  changeOperationType(type) {
    this.operationType = type;

    if (this.id) {
      return;
    }

    if (type === 'scheduled') {
      this.setProperties({
        standingEndingDate: null,
        standingOn: null,
      });
    }
  }

  @waitFor
  async check() {
    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'check',
        data: { request_transfer: this.serialize() },
      });
    } catch (error) {
      // @ts-expect-error
      error.errors = error.errors.map(({ detail: dt }) => {
        let { detail, code, source } = dt;

        source = source ? { pointer: `data/attributes${source.pointer}` } : source;

        return {
          code,
          detail,
          source,
        };
      });

      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors, error);
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-transfer': RequestTransferModel;
  }
}
