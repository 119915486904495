import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';

// @ts-expect-error
import { limitNumberOfNewLines } from 'qonto/utils/receivable-invoicing';

export default class ReceivableInvoiceSectionModel extends Model {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @attr title;
  // @ts-expect-error
  @attr description;

  /** @type {Item} */
  // @ts-expect-error
  @hasMany('receivable-invoice/item', { async: false, inverse: 'section' }) items;

  // @ts-expect-error
  @belongsTo('receivableInvoice', { async: true, inverse: 'sections' }) receivableInvoice;
  // @ts-expect-error
  @belongsTo('selfInvoice', { async: true, inverse: 'sections' }) selfInvoice;
  // @ts-expect-error
  @belongsTo('quote', { async: true, inverse: 'sections' }) quote;
  // @ts-expect-error
  @belongsTo('invoiceSubscription', { async: true, inverse: 'sections' }) invoiceSubscription;

  @action
  // @ts-expect-error
  updateDescription(description) {
    this.description = limitNumberOfNewLines(description, 10);
  }

  clearItemsWithNoId() {
    // @ts-expect-error
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    // @ts-expect-error
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseDiscountedTotalExcludingVat);
    }, 0);
  }

  get totalVatWithoutWelfareFund() {
    // @ts-expect-error
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseTotalVat);
    }, 0);
  }

  get vatRates() {
    // @ts-expect-error
    const itemsVatRates = [...new Set(this.items.map(item => item?.vatRate))];
    return itemsVatRates.sort();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/section': ReceivableInvoiceSectionModel;
  }
}
