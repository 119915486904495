import Model, { attr } from '@ember-data/model';

// @ts-expect-error
import ReceivableInvoicesSettingsValidations from 'qonto/validations/receivable-invoices-settings';

export interface Column {
  id: 'description' | 'quantity' | 'unit_price' | 'vat_rate' | 'subtotal';
  label?: string;
}

// @ts-expect-error
export default class ReceivableInvoicesSettingsModel extends Model.extend(
  ReceivableInvoicesSettingsValidations
) {
  //Attributes that will be deprecated with the release of quotes feature
  /** @type {string} */
  // @ts-expect-error
  @attr numberingPattern;
  /** @type {string} */
  // @ts-expect-error
  @attr nextNumber;
  /** @type {string} */
  // @ts-expect-error
  @attr nextInvoiceNumber;
  /** @type {string} */
  // @ts-expect-error
  @attr nextCreditNoteNumber;

  //Attributes that will be used for new settings v1 schema
  /** @type {'automatic'|'manual'} */
  // @ts-expect-error
  @attr numberingMode;
  /** @type {string} */
  // @ts-expect-error
  @attr contactEmail;
  /** @type {'goods' | 'services' | 'goods_and_services' | null} */
  // @ts-expect-error
  @attr transactionType;
  /** @type {'on_receipts' | 'compensated_for_sales' | 'exempt' | null} */
  // @ts-expect-error
  @attr vatPaymentCondition;
  /** @type {string | null} */
  // @ts-expect-error
  @attr discountConditions;
  /** @type {string | null} */
  // @ts-expect-error
  @attr latePaymentPenalties;
  /** @type {string | null} */
  // @ts-expect-error
  @attr legalFixedCompensation;
  /** @type {string} */
  // @ts-expect-error
  @attr creditNoteNextNumber;
  /** @type {string} */
  // @ts-expect-error
  @attr invoiceNextNumber;
  /** @type {string} */
  // @ts-expect-error
  @attr quoteNextNumber;
  /** @type {string} */
  // @ts-expect-error
  @attr invoiceNumberingPattern;
  /** @type {string} */
  // @ts-expect-error
  @attr creditNoteNumberingPattern;
  /** @type {string} */
  // @ts-expect-error
  @attr quoteNumberingPattern;
  /** @type {string} */
  // @ts-expect-error
  @attr invoiceNextNumberFormatted;
  /** @type {string} */
  // @ts-expect-error
  @attr quoteNextNumberFormatted;
  /** @type {string} */
  // @ts-expect-error
  @attr creditNoteNextNumberFormatted;
  /** @type {string} */
  // @ts-expect-error
  @attr selfInvoiceNextNumberFormatted;
  /** @type {string | null} */
  // @ts-expect-error
  @attr invoiceHeader;
  /** @type {string | null} */
  // @ts-expect-error
  @attr quoteHeader;
  /** @type {string | null} */
  // @ts-expect-error
  @attr invoiceFooter;
  /** @type {string | null} */
  // @ts-expect-error
  @attr quoteFooter;
  /** @type {string | null} */
  // @ts-expect-error
  @attr vatNumber;
  /** @type {string | null} */
  // @ts-expect-error
  @attr taxNumber;
  /** @type {string | null} */
  // @ts-expect-error
  @attr companyLeadership;
  /** @type {string | null} */
  // @ts-expect-error
  @attr districtCourt;
  /** @type {string | null} */
  // Note: this field corresponds to the RSC Number for FR orga, and to the commercial register number for DE orga
  // @ts-expect-error
  @attr commercialRegisterNumber;
  /** @type {string | null} */
  // @ts-expect-error
  @attr legalCapitalShare;
  // @ts-expect-error
  @attr colorText;
  // @ts-expect-error
  @attr colorTextAlt;
  // @ts-expect-error
  @attr colorTheme;
  /** a URL for the link to terms and conditions.
   *  @type {string | null} */
  // @ts-expect-error
  @attr tcLinkUrl;
  /** text for the link to terms and conditions
   * @type {string | null} */
  // @ts-expect-error
  @attr tcLinkText;
  @attr declare clientAddressName?: string;
  @attr declare invoiceTitle?: string;
  @attr declare quoteTitle?: string;
  @attr declare tableColumns?: Column[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoices-settings': ReceivableInvoicesSettingsModel;
  }
}
