import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { isNone, isPresent } from '@ember/utils';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { dropTask, task } from 'ember-concurrency';
import { and, bool, equal, or } from 'macro-decorators';

import { DOCUMENT_TYPE } from 'qonto/constants/attachments';
// @ts-expect-error
import { CHECK_COMPLETED_STATUSES } from 'qonto/constants/checks';
import { R_TRANSACTION_TYPES } from 'qonto/constants/direct-debit-collections';
// @ts-expect-error
import { CLAIM_STATUSES, STATUS, SUBJECT_TYPES } from 'qonto/constants/transactions';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import type AttachmentModel from 'qonto/models/attachment';
import type LabelModel from 'qonto/models/label';
import { serializeTransaction } from 'qonto/react/api/transactions/serializer';
import type {
  ActivityTag,
  EnrichmentData,
  OperationMethod,
  OperationType,
  SearchTransactionsResult,
  Side,
  Status,
  SubjectType,
  Transaction,
  Vat,
} from 'qonto/react/graphql';
import { SEARCH_TRANSACTIONS_QUERY_KEY } from 'qonto/react/hooks/use-search-transactions';
import type { CashflowTransactionCategory } from 'qonto/react/models/cash-flow-category';
import { queryClient as TanstackQueryGlobalStore } from 'qonto/react/react-bridge/custom-providers';
import { isAttachmentDeletable } from 'qonto/utils/attachment';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
import getClaimTypesForStatuses from 'qonto/utils/get-claim-types-for-statuses';

const oneSideOperationMethods = ['card', 'cheque'];

export default class TransactionModel extends Model {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare abilities: Services['abilities'];

  @attr('string') declare slug: string;
  @attr('string') declare sequentialId: string;
  @attr('date') declare emittedAt: Date;
  @attr('date') declare displayAt: Date;
  @attr('string') declare subjectId: string;
  @attr('string') declare subjectType: SubjectType;
  @attr('date') declare settledAt: Date | null;
  @attr('number') declare amount: number;
  @attr('number') declare localAmount: number;
  @attr('string') declare amountCurrency: string;
  @attr('string') declare localAmountCurrency: string;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  declare enrichmentData: EnrichmentData;

  /**
   * Single VAT management (v5)
   */
  @attr('number') declare vatAmount: number;
  @attr('string') declare vatCountry: string;
  @attr('number') declare vatRate: number;
  @attr('string') declare vatStatus: string;

  /**
   * Multi-VAT (v6)
   */
  @attr() declare vat: Vat;

  /**
   * External transactions (v7)
   */
  // @ts-expect-error
  @attr() counterparty;

  @attr('string') declare side: Lowercase<Side>;
  @attr('string') declare counterpartyName: string;
  @attr('string') declare rawCounterpartyName: string;
  @attr('string') declare note: string;
  @attr('string') declare description: string;
  @attr('boolean') declare attachmentRequested: boolean;
  @attr('string') declare status: Lowercase<Status>;
  // @ts-expect-error
  @attr() declinedReason;
  @attr('string') declare operationMethod: Lowercase<OperationMethod>;
  @attr('string') declare operationType: Lowercase<OperationType>;
  @attr('boolean') declare reported: boolean;
  @attr('boolean', { defaultValue: false }) declare blockCard: boolean;
  @attr('string') declare explanation: string;
  @attr('boolean') declare fx: boolean;
  @attr('string') declare initiatorId: string;
  @attr('boolean') declare attachmentLost: boolean;
  @attr('boolean') declare attachmentRequired: boolean;
  @attr('string') declare activityTag: Lowercase<ActivityTag>;
  @attr('string') declare cardTransactionStatus: string;
  @attr('boolean', { defaultValue: false }) declare qualifiedForAccounting: boolean;

  // @ts-expect-error
  @belongsTo('bank-account', { async: false, inverse: null }) bankAccount;
  @hasMany('label', { async: false, inverse: 'transaction' }) declare labels: LabelModel[];

  // @ts-expect-error
  @hasMany('attachment', { async: false, inverse: null }) attachments: AttachmentModel[];
  @attr() declare attachmentIds: Array<string>;
  @attr() declare attachmentSuggestionIds: Array<string>;
  @attr() declare automaticallyAddedAttachmentIds: Array<string>;

  @attr() declare categoryAssignment: CashflowTransactionCategory | null;

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('subject', { async: true, inverse: null, polymorphic: true }) subject;
  // @ts-expect-error
  @belongsTo('financing', { async: false, inverse: null }) financing;

  // @ts-expect-error
  @equal('side', 'debit') debit;
  // @ts-expect-error
  @equal('side', 'credit') credit;
  // @ts-expect-error
  @equal('operationMethod', 'pay_later') isPayLater;
  // @ts-expect-error
  @equal('operationMethod', 'biller') isBiller;
  // @ts-expect-error
  @equal('operationMethod', 'card_acquirer_payout') isTapToPay;
  // @ts-expect-error
  @equal('subjectType', SUBJECT_TYPES.FINANCING_INSTALLMENT) isFinancingInstallment;
  // @ts-expect-error
  @equal('subjectType', SUBJECT_TYPES.FINANCING_INCOME) isFinancingIncome;

  get attachmentCount() {
    return this.attachmentIds?.length ?? 0;
  }

  get isPagoPaPayment() {
    return this.operationMethod === 'pagopa_payment';
  }

  get isNrcPayment() {
    return this.subjectType === SUBJECT_TYPES.NRC_PAYMENT;
  }

  get isUnknown() {
    return this.operationMethod === 'other';
  }

  get signedAmount() {
    if (this.amount >= 0) {
      return this.debit ? -this.amount : this.amount;
    }
    return this.amount;
  }

  get signedLocalAmount() {
    return this.debit ? -this.localAmount : this.localAmount;
  }

  get completed() {
    if (this.isCheckTransaction) {
      return CHECK_COMPLETED_STATUSES.includes(this.subject.get('status'));
    }

    return this.status === STATUS.COMPLETED;
  }

  // @ts-expect-error
  @equal('status', 'reversed') reversed;

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.declined || this.reversed) {
      avatarStatusIconSrc = 'status_stop-xs';
    } else if (this.pending) {
      avatarStatusIconSrc = 'status_processing-s';
    }

    return {
      mediumLogo: this.enrichmentData.logo?.medium ?? activityTagSVG,
      smallLogo: this.enrichmentData.logo?.small ?? activityTagSVG,
      icon: avatarStatusIconSrc,
    };
  }

  get refunded() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'refunded';
    }
  }

  get pending() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'pending';
    }

    return this.status === 'pending';
  }

  get declined() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'declined';
    }

    return this.status === 'declined';
  }

  get canceled() {
    if (this.isCheckTransaction) {
      return this.subject.get('status') === 'canceled';
    }

    return (
      this.status === 'declined' &&
      (this.declinedReason === 'user_canceled' || this.declinedReason === 'ops_canceled')
    );
  }

  get isFx() {
    let amountCurrency = this.amountCurrency;
    let localAmountCurrency = this.localAmountCurrency;
    if (amountCurrency && localAmountCurrency) {
      return amountCurrency.toLowerCase() !== localAmountCurrency.toLowerCase();
    }

    return false;
  }

  get isExternalTransaction() {
    return isNone(this.subjectId) && isNone(this.subjectType);
  }

  get isCardTransaction() {
    if (this.isExternalTransaction) return this.operationMethod === 'card';

    return this.subjectType?.toLowerCase() === 'card';
  }

  get isCheckTransaction() {
    if (this.isExternalTransaction) return this.operationMethod === 'cheque';

    return this.subjectType?.toLowerCase() === 'check';
  }

  get isTransfer() {
    if (this.isExternalTransaction) return this.operationMethod === 'transfer';

    return this.subjectType?.toLowerCase() === 'transfer';
  }

  get isDirectDebitCollection() {
    // @ts-expect-error
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit_collection';

    return this.subjectType === SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION;
  }

  get isDirectDebitHold() {
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit_hold';

    return this.subjectType === SUBJECT_TYPES.DIRECT_DEBIT_HOLD;
  }

  get isSDDReturnedAfterSettlementDate() {
    return (
      this.isDirectDebitCollection &&
      this.subject.get('rTransactionType') &&
      this.subject.get('rTransactionType') === R_TRANSACTION_TYPES.RETURN
    );
  }

  get isSDDRefunded() {
    return (
      this.isDirectDebitCollection &&
      this.subject.get('rTransactionType') &&
      this.subject.get('rTransactionType') === R_TRANSACTION_TYPES.REFUND
    );
  }

  // @ts-expect-error
  @equal('operationMethod', 'tax') isTax;

  // @ts-expect-error
  @and('isTransfer', 'subject.isDeclined') isDeclinedTransfer;

  // @ts-expect-error
  @and('isTransfer', 'subject.instant') isInstantTransfer;

  // @ts-expect-error
  @and('isTransfer', 'subject.isRepeatable') isRepeatableTransfer;

  // @ts-expect-error
  @and('isTransfer', 'subject.isInternationalOut') isInternationalOutTransfer;

  get isIncome() {
    return this.subjectType?.toLowerCase() === 'income';
  }

  get isDirectDebit() {
    if (this.isExternalTransaction) return this.operationMethod === 'direct_debit';

    return this.subjectType?.toLowerCase() === 'directdebit';
  }

  // @ts-expect-error
  @bool('subject.isFee') isFee;
  // @ts-expect-error
  @bool('subject.isSeizure') isSeizure;
  // @ts-expect-error
  @bool('subject.isCreditNote') isCreditNote;
  // @ts-expect-error
  @bool('subject.isSubscription') isSubscription;
  // @ts-expect-error
  @or('isFee', 'isCreditNote', 'isSubscription') isBilling;

  // @ts-expect-error
  @equal('subjectType', 'SwiftIncome') isSwiftIncome;

  get operationName() {
    let operationType = this.get('operationType');

    if (oneSideOperationMethods.includes(operationType)) {
      return operationType;
    }

    if (operationType === 'transfer' && this.get('isSeizure')) {
      return OPERATION_TYPES.SEIZURE;
    }

    if (operationType === 'f24' && this.isProxiedF24) {
      return 'f24_payment_proxied';
    }

    let desc = this.get('debit') ? 'out' : 'in';
    return `${operationType}_${desc}`;
  }

  get isNewF24() {
    let subjectIdExists = isPresent(this.subjectId);
    return this.subjectType?.toLowerCase() === 'f24payment' && subjectIdExists;
  }

  get isProxiedF24() {
    let subjectIdExists = !isNone(this.subjectId);

    return (
      this.subjectType?.toLowerCase() === 'f24payment' &&
      Boolean(this.subject.get('isProxied')) &&
      subjectIdExists
    );
  }

  get operationTypeTranslations() {
    return {
      biller: this.intl.t('transactions.operation-types.biller'),
      card: this.intl.t('transactions.operation-types.card'),
      cheque: this.intl.t('transactions.operation-types.cheque'),
      direct_debit: this.intl.t('transactions.operation-types.direct-debit'),
      direct_debit_hold: this.intl.t('transactions.operation-types.direct-debit-hold'),
      pagopa_payment: this.intl.t('transactions.operation-types.pagopa-payment'),
      tax: this.intl.t('transactions.operation-types.tax'),
      transfer: this.intl.t('transactions.operation-types.transfer'),
      pay_later: this.intl.t('transactions.operation-types.pay-later'),
      other: this.intl.t('transactions.operation-types.unknown'),
      card_acquirer_payout: this.intl.t('transactions.operation-types.card-acquirer-payout'),
    };
  }

  get methodLabel() {
    let operationMethod = this.operationMethod;
    if (operationMethod) {
      let method = this.operationTypeTranslations[operationMethod];

      if (operationMethod === 'card') {
        // @ts-expect-error
        let memberName = this.get('initiator.memberName');
        if (memberName) {
          return { method, name: memberName };
        }
      }
      return { method };
    }
  }

  get attachmentsFiles() {
    return this.attachments
      .filter(f => Boolean(f.file))
      .map(attachment => {
        let DOCUMENT_TYPES = Object.values(DOCUMENT_TYPE);
        let { file, probativeAttachment, vatStatus, thumbnail, documentType } = attachment;
        let thumbnailUrl = thumbnail?.fileUrl;
        let attachmentId = attachment.id;
        let probativeStatus = probativeAttachment?.status;
        let isValidDocumentType = DOCUMENT_TYPES.includes(documentType);
        let hasCrossSectionSyncEnabled = this.abilities.can(
          'viewImproveXSectionFeature attachment'
        );
        let attachmentProcessing = this.abilities.can('probate attachment')
          ? vatStatus === 'vat_detecting' || probativeStatus === 'pending'
          : false;
        let isProcessing = hasCrossSectionSyncEnabled ? !isValidDocumentType : attachmentProcessing;
        let createdAt = attachment.createdAt;
        let attachmentFile = probativeStatus === 'available' ? probativeAttachment : file;
        let deletable = isAttachmentDeletable(attachment);

        if (probativeStatus === 'corrupted') {
          // we copy all file infos in the probative attachment object
          // as corrupted probativeAttachment object only contains a status
          return { ...file, status: probativeStatus, createdAt, documentType, attachmentId };
        }

        // a file could be attach to a transaction without having been uploaded with web app,
        // as suggested attachment from invoice collector for example,
        // `file.state` could in that case never be defined.
        if ('state' in file && file.state !== 'uploaded') {
          // we use Object.assign since it preserves the local file
          // uploading properties like file.progress and file.state
          return Object.assign(attachmentFile, {
            isProcessing,
            documentType,
            thumbnailUrl,
            deletable,
            createdAt,
            attachmentId,
          });
        }

        // we use destructing instead of Object.assign since the
        // latter does not update when the isProcessing property changes

        return {
          ...attachmentFile,
          isProcessing,
          thumbnailUrl,
          deletable,
          createdAt,
          documentType,
          attachmentId,
        };
      });
  }

  get vatItems() {
    return this.vat.items;
  }

  get hasUndeletableAttachment() {
    let { isPayLater, debit, isTransfer } = this;
    return isPayLater && debit && isTransfer;
  }

  get chargebackDisputingTypes() {
    let claims = this.store.peekAll('claim').toArray();

    return getClaimTypesForStatuses({
      claims,
      filterStatuses: [CLAIM_STATUSES.review],
      transactionId: this.id,
    });
  }

  get chargebackDisputedTypes() {
    let claims = this.store.peekAll('claim').toArray();
    let disputedStatuses = [CLAIM_STATUSES.rejected, CLAIM_STATUSES.approved];

    return getClaimTypesForStatuses({
      claims,
      filterStatuses: disputedStatuses,
      transactionId: this.id,
    });
  }

  get chargebackApprovedTypes() {
    let claims = this.store.peekAll('claim').toArray();

    return getClaimTypesForStatuses({
      claims,
      filterStatuses: [CLAIM_STATUSES.approved],
      transactionId: this.id,
    });
  }

  // @ts-expect-error
  getVatItem(index) {
    return this.vatItems[index];
  }

  // @ts-expect-error
  _handleError(error) {
    // @ts-expect-error
    if (error instanceof InvalidError && error.errors) {
      // @ts-expect-error
      let errors = errorsArrayToHash(error.errors);
      this.networkManager.errorModelInjector(this, errors);
    }

    throw error;
  }

  #updateTanstackQueryStore(
    model?: TransactionModel | null,
    customAttributes?: Partial<Transaction>
  ): void {
    let currentModel = model || this;
    TanstackQueryGlobalStore.setQueriesData<SearchTransactionsResult>(
      { queryKey: [SEARCH_TRANSACTIONS_QUERY_KEY] },
      transactionStore => {
        return {
          ...(transactionStore as SearchTransactionsResult),
          transactions:
            transactionStore?.transactions.map(transaction => {
              if (transaction.id === currentModel.id) {
                return {
                  ...transaction,
                  ...serializeTransaction(currentModel, customAttributes),
                };
              }
              return transaction;
            }) || [],
        };
      }
    );
  }

  @waitFor
  // @ts-expect-error known error: cannot correctly cast "this" type
  async save(): Promise<TransactionModel> {
    let result = await super.save(...arguments);
    this.#updateTanstackQueryStore();
    return result;
  }

  @waitFor
  async requestAttachment() {
    let response = await apiAction(this, { method: 'PUT', path: 'request_attachment' });
    this.store.pushPayload(response);

    if (response && response.transaction) {
      this.#updateTanstackQueryStore(null, {
        attachmentRequestedAt: response.transaction?.attachment_requested_at,
        attachmentRequestedById: response.transaction?.attachment_requested_by_id,
      });
    }
  }

  // @ts-expect-error
  linkAttachment(attachments) {
    return this.linkAttachmentTask.perform(attachments);
  }

  linkAttachmentTask = task({ maxConcurrency: 5, enqueue: true }, async attachments => {
    // @ts-expect-error
    await this._linkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  // @ts-expect-error
  async _linkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'link_attachments', data });
      this.store.pushPayload(response);
      this.#updateTanstackQueryStore();
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  // @ts-expect-error
  async updateActivityTag(activityTag) {
    let data = { transaction: { activity_tag: activityTag } };
    let response = await apiAction(this, { method: 'PUT', path: 'activity', data });
    this.store.pushPayload(response);
    this.#updateTanstackQueryStore();
  }

  @waitFor
  // @ts-expect-error
  async updateAttachmentStatuses({ required, lost, organizationId, transactionIds }) {
    let data = {
      lost,
      organization_id: organizationId,
      transaction_ids: transactionIds,
    };

    if (this.abilities.can('update attachment required status transaction')) {
      // @ts-expect-error
      data.required = required;
    }

    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'PATCH',
        path: 'attachment_statuses',
        data,
      });
      this.store.pushPayload(response);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  // @ts-expect-error
  async markAsQualify({ organizationId, transactionIds }) {
    let data = { organization_id: organizationId, transaction_ids: transactionIds };
    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'qualify_for_accounting',
        data,
      });
      this.store.pushPayload(response);

      if (response.transactions?.[0].id) {
        this.#updateTanstackQueryStore(
          this.store.peekRecord('transaction', response.transactions[0].id)
        );
      }
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  // @ts-expect-error
  async markAsDisqualify({ organizationId, transactionIds }) {
    let data = { organization_id: organizationId, transaction_ids: transactionIds };
    try {
      let response = await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'disqualify_for_accounting',
        data,
      });
      this.store.pushPayload(response);

      if (response.transactions?.[0].id) {
        this.#updateTanstackQueryStore(
          this.store.peekRecord('transaction', response.transactions[0].id)
        );
      }
    } catch (error) {
      this._handleError(error);
    }
  }

  // @ts-expect-error
  unlinkAttachment(attachments) {
    return this.unlinkAttachmentTask.perform(attachments);
  }

  unlinkAttachmentTask = dropTask(async attachments => {
    // @ts-expect-error
    await this._unlinkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  // @ts-expect-error
  async _unlinkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };
    try {
      let response = await apiAction(this, { method: 'PATCH', path: 'unlink_attachments', data });
      this.store.pushPayload(response);
      this.#updateTanstackQueryStore();
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async markAttachmentAsLost() {
    let response = await apiAction(this, { method: 'PUT', path: 'mark_attachment_as_lost' });
    this.store.pushPayload(response);
    this.#updateTanstackQueryStore();
  }

  @waitFor
  async unmarkAttachmentAsLost() {
    let response = await apiAction(this, { method: 'PUT', path: 'unmark_attachment_as_lost' });
    this.store.pushPayload(response);
    this.#updateTanstackQueryStore();
  }

  @waitFor
  async markAttachmentAsRequired() {
    let response = await apiAction(this, { method: 'PUT', path: 'mark_attachment_as_required' });
    this.store.pushPayload(response);
    this.#updateTanstackQueryStore();
  }

  @waitFor
  async unmarkAttachmentAsRequired() {
    let response = await apiAction(this, { method: 'PUT', path: 'unmark_attachment_as_required' });
    this.store.pushPayload(response);
    this.#updateTanstackQueryStore();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    transaction: TransactionModel;
  }
}
