import Model, { attr } from '@ember-data/model';
import { assert } from '@ember/debug';
import { service, type Registry as Services } from '@ember/service';

import {
  type Column,
  DEFAULT_COLUMNS,
  type DisplayColumn,
  LABEL_COLUMNS_DEFAULTS,
} from 'qonto/constants/table-view';

export default class TableViewModel extends Model {
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];

  @attr('string') name!: string;
  @attr('string') resource!: string;
  @attr('number') position!: number;

  @attr() columns!: Column[];

  static updateColumn(columns: DisplayColumn[], updatedColumn: Partial<DisplayColumn>) {
    let oldColumn = columns.find(column => column.id === updatedColumn.id);
    if (!oldColumn) {
      throw new Error(`Column with id ${updatedColumn.id} not found`);
    }

    let newColumn = { ...oldColumn, ...updatedColumn };

    let visibilityChanged = oldColumn.isVisible !== newColumn.isVisible;
    let positionChanged = oldColumn.position !== newColumn.position;

    if (visibilityChanged && !positionChanged) {
      if (newColumn.isVisible) {
        let lastVisibleColumnIndex = columns
          .filter(column => column.isVisible)
          .map(column => column.position)
          .pop();

        assert('Pinned column must always be visible', lastVisibleColumnIndex !== undefined);

        let onlyPinnedColumnIsVisible = lastVisibleColumnIndex === 0;
        newColumn.position = onlyPinnedColumnIsVisible
          ? 1 // Pinned column is always first
          : lastVisibleColumnIndex + 1;
      } else {
        let firstHiddenColumnIndex = columns
          .filter(column => !column.isVisible)
          .map(column => column.position)
          .shift();
        let allColumnsAreVisible = firstHiddenColumnIndex === undefined;

        newColumn.position = allColumnsAreVisible
          ? columns.length - 1
          : firstHiddenColumnIndex! - 1;
      }
    }

    const filteredColumns = columns.filter(column => column.id !== updatedColumn.id);
    filteredColumns.splice(newColumn.position, 0, newColumn);

    return filteredColumns.map((column, index) => ({ ...column, position: index }));
  }

  static filterColumnsByPermissions(columns: DisplayColumn[], abilities: Services['abilities']) {
    return columns?.filter(column => {
      if (
        column.id === 'bkVerificationStatus' &&
        abilities.cannot('qualify for accounting transactions')
      ) {
        return false;
      }
      return true;
    });
  }

  static buildColumns(
    view: TableViewModel,
    labelListsIds: string[],
    abilities: Services['abilities']
  ): DisplayColumn[] {
    const labelListColumns = labelListsIds.map((label, index) => ({
      id: label,
      width: LABEL_COLUMNS_DEFAULTS.width,
      position: LABEL_COLUMNS_DEFAULTS.startIndex + index,
      pinned: LABEL_COLUMNS_DEFAULTS.pinned,
      isVisible: LABEL_COLUMNS_DEFAULTS.isVisible,
    }));

    const defaultColumns = DEFAULT_COLUMNS.map((column, index) => ({
      ...column,
      position:
        index +
        (labelListsIds.length > 0 && index >= LABEL_COLUMNS_DEFAULTS.startIndex
          ? labelListsIds.length
          : 0),
    }));

    const defaultColumnsWithLabelLists = this.filterColumnsByPermissions(
      [...defaultColumns, ...labelListColumns],
      abilities
    );

    const visibleColumns = (view ? view.columns : defaultColumnsWithLabelLists)
      .map(column => {
        const isDefaultColumn = 'isVisible' in column;
        return isDefaultColumn ? (column as DisplayColumn) : { ...column, isVisible: true };
      })
      .filter(column => column.isVisible);

    const hiddenColumns = defaultColumnsWithLabelLists
      .filter(defaultColumn => !visibleColumns.some(column => column.id === defaultColumn.id))
      .map(column => ({
        ...column,
        isVisible: false,
      }))
      .sort((a, b) => a.id.localeCompare(b.id));

    return [...visibleColumns, ...hiddenColumns].map(
      (column, index) => ({ ...column, position: index }) as DisplayColumn
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'table-view': TableViewModel;
    tableView: TableViewModel;
  }
}
